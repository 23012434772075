<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
  <div class="sign-in bg-gray-3">
    <a-row
      type="flex"
      :gutter="24"
      justify="space-around"
      align="middle"
      style="padding-top: 90px"
    >
      <a-col :span="24" :sm="24" :md="24" :lg="24" :xl="24" class="mt-20">
        <!-- Job Listings -->
        <div class="max-w-7xl mx-auto px-4 py-6">
          <a-row style="padding-bottom: 50px">
            <a-col :span="24" class="mb-20 flex justify-between items-center">
              <div class="">
                <h1 class="font-semibold" style="font-size: 36px">
                  Tutoring Jobs
                </h1>
                <span class="text-primary pl-1">
                  {{ jobs.length }} Jobs • Updated today
                </span>
              </div>

              <div class="">
                <!-- <a-badge
                  v-for="option in filterItems"
                  :key="option.uuid"
                  style="
                    font-size: 14px !important;
                    margin: 2px 3px;
                    padding: 5px;
                  "
                  :count="`${option.name}`"
                  @click="onSelectFilterItem(option.uuid)"
                  :number-style="
                    filter.timestamp == option.uuid
                      ? {
                          backgroundColor: '#734EBF !important',
                          color: '#FFFFFF',
                          paddingTop: '5px',
                          paddingRight: '18px',
                          paddingLeft: '18px',
                          height: '32px',
                          fontSize: '14px',
                        }
                      : {
                          border: '1px solid #734EBF !important',
                          color: '#734EBF',
                          backgroundColor: 'transparent !important',
                          paddingTop: '5px',
                          paddingRight: '18px',
                          paddingLeft: '18px',
                          height: '32px',
                          fontSize: '14px',
                        }
                  "
                /> -->
              </div>
            </a-col>
          </a-row>
          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full"
            v-if="!loadingData && jobs.length > 0"
          >
            <div
              v-for="(job, index) in jobs"
              :key="job.uuid"
              class="bg-white rounded-lg shadow hover:shadow-lg transition-shadow"
            >
              <!-- Card Header -->
              <div class="p-6">
                <div class="flex justify-between items-start">
                  <div class="w-full">
                    <div class="flex justify-between items-center">
                      <h2
                        :style="{ color: '#54398D' }"
                        class="text-xl font-bold flex-1"
                      >
                        {{ job.system.name }}
                      </h2>
                      <span class="text-sm top-2 right-0 text-gray-600">
                        {{ getPostedDate(job.createdAt) }}
                      </span>
                    </div>

                    <div class="flex items-center gap-2 mt-1">
                      <font-awesome-icon
                        :icon="['far', 'building']"
                        :style="{ color: '#54398D' }"
                      />

                      <span class="text-sm text-gray-600">{{
                        job.level.name
                      }}</span>
                      <span class="text-sm text-gray-600">•</span>
                      <span class="text-sm text-gray-600">
                        {{ job.grade.name }}
                      </span>
                    </div>
                    <div class="flex items-start gap-2 mt-1">
                      <font-awesome-icon
                        :icon="['fas', 'graduation-cap']"
                        :style="{ color: '#54398D' }"
                        class="mt-2"
                      />
                      <div class="flex flex-wrap">
                        <div
                          :style="{
                            backgroundColor: '#f5f3fa',
                            color: '#54398D',
                          }"
                          class="px-1 py-1 rounded-lg border-1 inline-block mr-2 mb-2"
                          v-for="sub in job.subjects"
                          :key="sub.uuid"
                        >
                          {{ sub.name }}
                        </div>
                      </div>
                      <!--  -->
                    </div>
                    <div
                      :style="{ color: '#54398D' }"
                      class="font-semibold mt-2"
                    >
                      TZS {{ job.totalAmount.toLocaleString() }} per month
                    </div>
                  </div>
                </div>
              </div>

              <!-- Card Content -->
              <div class="p-6 pt-0 mt-auto">
                <div class="space-y-4">
                  <!-- Sessions and Start Date -->
                  <div class="space-y-2">
                    <div class="flex items-center gap-2 text-gray-600">
                      <font-awesome-icon :icon="['far', 'clock']" />
                      16 sessions per month
                    </div>
                    <div class="flex items-center gap-2 text-gray-600">
                      <font-awesome-icon :icon="['far', 'calendar-days']" />
                      Starts
                      {{ $Moment(job.startDate).format("DD MMMM YYYY") }}
                    </div>
                  </div>

                  <!-- Location -->
                  <div
                    :style="{
                      backgroundColor: '#f5f3fa',
                      borderColor: '#54398D',
                    }"
                    class="p-3 rounded-lg border-2"
                  >
                    <div
                      class="flex items-center justify-center gap-2 font-medium"
                      :style="{ color: '#54398D' }"
                    >
                      <font-awesome-icon :icon="['fas', 'location-dot']" />
                      {{ job.studentDetails.physicalAddress }},
                      {{
                        job.studentDetails.region != null
                          ? job.studentDetails.region.name
                          : "Dar es Salaam"
                      }}
                    </div>
                  </div>

                  <!-- Apply Button -->
                  <a-button
                    type="primary"
                    style="background-color: #54398d"
                    class="w-full flex items-center justify-center gap-2 text-white px-4 py-2 rounded-lg hover:opacity-90 transition-opacity"
                    @click="submitTutorResponse(job, index)"
                    :loading="job.loading"
                    :disabled="
                      !job.requestedTutors.includes($route.params.tutorUuid)
                    "
                  >
                    <i class="fas fa-paper-plane"></i>
                    Apply
                  </a-button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!loadingData && jobs.length == 0">
            <a-row
              type="flex"
              :gutter="[24, 24]"
              justify="space-around"
              align="middle"
              class=""
              style="min-height: 60vh"
            >
              <a-col
                :span="24"
                :md="12"
                :lg="12"
                :xl="{ span: 6, offset: 0 }"
                class="text-center justify-items-center"
              >
                <h6>No jobs are available for now</h6>
              </a-col>
            </a-row>
          </div>

          <div v-if="loadingData">
            <a-row
              type="flex"
              :gutter="[24, 24]"
              justify="space-around"
              align="middle"
              class=""
              style="min-height: 60vh"
            >
              <a-col
                :span="24"
                :md="12"
                :lg="12"
                :xl="{ span: 6, offset: 0 }"
                class="text-center justify-items-center"
              >
                <a-spin class="text-primary" size="large" />
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
    </a-row>

    <a-modal v-model="showCongratsModal" :footer="null">
      <a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
        <a-col :span="24" class="my-0 py-0">
          <h6 style="font-weight: normal; font-size: 18px" class="text-center">
            Awesome! You’ve Been Assigned a <br />New Tutoring Job 🎉
          </h6>
        </a-col>
      </a-row>

      <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
        <a-col :span="24" class="my-0 py-0">
          <p>
            Congratulations on being the first to respond to the tutoring
            request! You’ve been successfully assigned to teach
            <span
              v-for="subject in selectedJob.subjects"
              :key="subject.uuid"
              class="font-semibold"
            >
              {{ subject.name }},
            </span>
            for
            <span class="font-semibold">{{
              selectedJob.studentDetails != null
                ? selectedJob.studentDetails.firstName
                : ""
            }}</span
            >. <br />You’ll shortly receive a text message with the parent’s
            contact information and additional instructions. Please reach out to
            them promptly to confirm the details and schedule.
          </p>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
import VueCountryCode from "vue-country-code-select";
import { notification } from "ant-design-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import { PhHorse, PhHeart, PhCube } from "@phosphor-icons/vue";

export default {
  components: {
    VueCountryCode,
    FontAwesomeIcon,
    // PhHorse,
  },

  data() {
    return {
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      // form: null,
      // formPassword: null,

      genders: [
        { uuid: false, name: "Male" },
        { uuid: true, name: "Female" },
      ],

      filterItems: [
        { uuid: "all", name: "All", icon: " ❤️" },
        { uuid: "today", name: "Today", icon: " 🎓" },
        { uuid: "yesterday", name: "Yesterday", icon: " ❤️" },
      ],

      current: 1,
      steps: [
        {
          title: "Who are you?",
          content: "First-content",
        },
        {
          title: "Basic Details",
          content: "First-content",
        },
        {
          title: "Account Details",
          content: "Second-content",
        },
        // {
        // 	title: 'Password',
        // 	content: 'Third-content',
        // },
        {
          title: "Verify Account",
          content: "Third-content",
        },
      ],

      loadingData: false,
      loadingApproval: false,

      filter: {
        timestamp: "all",
      },

      requestDetails: {
        uuid: null,
        passcode: null,
        clazz: {},
        student: {},
        status: null,
      },

      errorAccessCode: {
        message: null,
        show: false,
      },

      showCongratsModal: false,

      jobs: [],

      selectedJob: {},
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.passcodeForm = this.$form.createForm(this, { name: "passcode_form" });
  },
  created() {
    this.getTutorRequests();
  },
  methods: {
    getPostedDate(creaetedAt) {
      const date = new Date(creaetedAt);
      const today = new Date();

      const inputYear = date.getFullYear();
      const inputMonth = date.getMonth();
      const inputDay = date.getDate();

      const todayYear = today.getFullYear();
      const todayMonth = today.getMonth();
      const todayDay = today.getDate();

      if (
        inputYear === todayYear &&
        inputMonth === todayMonth &&
        inputDay === todayDay
      ) {
        return "Today";
      }

      const yesterday = new Date();
      yesterday.setDate(todayDay - 1);

      if (
        inputYear === yesterday.getFullYear() &&
        inputMonth === yesterday.getMonth() &&
        inputDay === yesterday.getDate()
      ) {
        return "Yesterday";
      }

      return `${this.$Moment(date).format("DD MMM")}`;
      // date.toLocaleDateString(undefined, {
      //   year: "numeric",
      //   month: "short",
      //   day: "numeric",
      // });
    },
    handleApply(jobId) {
      console.log(`Application sent for job ${jobId}`);
      // Add your application handling logic here
    },

    next() {
      this.current++;
    },

    previousScreen() {
      if (this.current > 0) {
        this.current--;
      }
    },

    onSelectFilterItem(value) {},

    formatName(name) {
      let formattedName =
        name != null && name.length > 1
          ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}`
          : "";
      return formattedName;
    },

    handleCloseError() {
      this.errorAccessCode.show = false;
    },

    async getTutorRequests() {
      this.loadingData = true;

      let params = {
        // populate: true,
        orderBy: "createdAt",
        order: "DESC",
        page: 1,
        size: 1000000,
      };

      //   uuid=${this.$route.params.uuid}&populate=true
      let url = `${this.$BACKEND_URL}/profiles/lead-classes/tutor-requests`;

      this.$AXIOS
        .get(url, { params })
        .then((response) => {
          if (response.data.data != null && response.data.data.length > 0) {
            // console.log();
            this.jobs = response.data.data
              // .filter(
              //   (job) =>
              //     job.classDetails != null &&
              //     job.classDetails.status == "waiting for tutor confirmation" &&
              //     job.requestedTutors.includes(this.$route.params.tutorUuid)
              // )
              .map((job) => {
                job.system =
                  job.classDetails != null &&
                  job.classDetails.educationSystem != null
                    ? job.classDetails.educationSystem
                    : {};
                job.level =
                  job.classDetails != null &&
                  job.classDetails.educationLevel != null
                    ? job.classDetails.educationLevel
                    : {};
                job.grade =
                  job.classDetails != null &&
                  job.classDetails.educationGrade != null
                    ? job.classDetails.educationGrade
                    : {};
                job.subjects =
                  job.classDetails != null && job.classDetails.subjects != null
                    ? job.classDetails.subjects
                    : [];
                job.startDate =
                  job.classDetails != null && job.classDetails.startDate != null
                    ? job.classDetails.startDate
                    : null;
                job.endDate =
                  job.classDetails != null && job.classDetails.endDate != null
                    ? job.classDetails.endDate
                    : null;
                job.studentDetails =
                  job.classDetails != null && job.classDetails.student != null
                    ? job.classDetails.student
                    : {};
                job.parentDetails =
                  job.classDetails != null &&
                  job.classDetails.student != null &&
                  job.classDetails.student.parent != null
                    ? job.classDetails.student.parent
                    : {};
                job.totalAmount =
                  job.classDetails != null &&
                  job.classDetails.totalAmount != null
                    ? job.classDetails.totalAmount * 0.8
                    : 150000 * 0.8;
                job.loading = false;

                return { ...job };
              });

            // this.requestDetails.uuid = response.data.data.uuid;
            // this.requestDetails.passcode = response.data.data.passcode;
            // this.requestDetails.clazz = response.data.data.clazz;
            // this.requestDetails.student = response.data.data.student;
            // this.requestDetails.status = response.data.data.status;

            this.loadingData = false;
          }
        })
        .catch(async (error) => {
          this.loadingData = false;
        });
    },

    handleSubmitPasscode(e) {
      e.preventDefault();

      this.passcodeForm.validateFields((err, values) => {
        if (!err) {
          let payload = values;

          if (this.requestDetails.passcode == payload.passcode) {
            this.current++;
          } else {
            this.errorAccessCode.message = "Wrong or Invalid passcode";
            this.errorAccessCode.show = true;
          }
        }
      });
    },

    submitTutorResponse(request, index) {
      this.jobs[index].loading = true;

      this.selectedJob = this.jobs[index];

      let url = `${this.$BACKEND_URL}/profiles/lead-tutor-requests/response/${request.uuid}`;

      let payload = {
        clazz: request.clazz != null ? request.clazz : null,
        tutor: this.$route.params.tutorUuid,
        parentName: request.parentDetails.firstName,
        parentPhone:
          request.parentDetails.phone != null &&
          request.parentDetails.phone.length == 10
            ? `+255${request.parentDetails.phone.substring(1)}`
            : request.parentDetails.phone,
        subjects: request.subjects,
        grade: request.grade,
        level: request.level,
        system: request.system,
        startDate: this.$Moment(request.startDate).format("DD MMM YYYY"),
        studentRegion: request.studentDetails.region,
        studentLocation: request.studentDetails.physicalAddress,
        status: "approved",
        responseDate: this.$Moment(),
      };

      console.log(payload);

      this.$AXIOS
        .patch(url, payload)
        .then(async (response) => {
          if (response.status >= 200 && response.status < 210) {
            // this.$router.push("/");
            this.showCongratsModal = true;
            this.getTutorRequests();
          }
          this.loadingApproval = false;
        })
        .catch((err) => {
          this.loadingApproval = false;
          console.log(err);
        });
    },

    notify(message, type) {
      notification[type]({
        message: message,
        description: "",
        placement: "topRight",
        duration: 3,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #ffffff;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: red;
}
#signinBtn,
#signinBtn:hover {
  background-color: #632163 !important;
  color: white !important;
}
#submitUsernameBtn,
#submitUsernameBtn:hover,
#submitTokenBtn,
#submitTokenBtn:hover {
  background-color: #632163 !important;
  color: white !important;
}
#backBtn,
#backBtn:hover {
  color: #632163 !important;
  border-color: #632163 !important;
  background-color: white !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: red !important;
}

.custome-step
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: red !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  //    background-color: #632163 !important;
  color: #ffffff;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: #632163 !important;
  background: #632163 !important;
}

#divId {
  display: inline-block !important;
}
</style>
