<template>
  <!--  -->
  <div @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent>
    <!-- <div> -->
    <div v-if="!loadingPath && pdfFilePath != null && pdfFilePath.length > 0">
      <a-row :gutter="24">
        <a-col :span="24" class="text-center my-5 py-5">
          <a-button type="link" :disabled="zoom == 8" @click="rotate -= 90"
            ><a-icon type="undo" theme="outlined"
          /></a-button>
          <a-button type="link" :disabled="zoom == 24" @click="rotate += 90"
            ><a-icon type="redo" theme="outlined"
          /></a-button>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <a-button type="link" :disabled="zoom == 8" @click="zoomOutPdf"
            ><a-icon type="minus" theme="outlined"
          /></a-button>
          Zoom
          <a-button type="link" :disabled="zoom == 24" @click="zoomInPdf"
            ><a-icon type="plus" theme="outlined"
          /></a-button>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <input id="inputPage" v-model.number="page" type="number" /> &nbsp;
          of&nbsp; {{ numPages }} &nbsp;&nbsp; &nbsp;&nbsp;
          <a-button type="link" :disabled="page == 1" @click="previousPage"
            ><a-icon type="left" theme="outlined"
          /></a-button>
          Page
          <a-button type="link" :disabled="page == numPages" @click="nextPage"
            ><a-icon type="right" theme="outlined"
          /></a-button>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center" align="top">
        <a-col :span="12" class="text-center my-5 py-5">
          <a-progress
            strokeColor="#734EBF"
            trailColor="success"
            v-if="loadedRatio > 0 && loadedRatio < 1"
            :percent="Math.floor(loadedRatio * 100)"
          />
        </a-col>
      </a-row>

      <a-row type="flex" justify="center" align="top">
        <a-col :span="zoom" class="">
          <pdf
            v-if="!loadingPath"
            ref="pdf"
            :style="'border: 1px solid transparent;'"
            :src="proxyUrl + encodeURI(pdfFilePath)"
            :page="page"
            :rotate="rotate"
            @num-pages="numPages = $event"
            @link-clicked="page = $event"
            @progress="loadedRatio = $event"
          ></pdf>
        </a-col>
      </a-row>
    </div>

    <div
      v-if="
        !loadingPath &&
        message.length > 0 &&
        (pdfFilePath == null ||
          pdfFilePath == 'null' ||
          pdfFilePath.length == 0)
      "
    >
      <a-row
        type="flex"
        :gutter="24"
        justify="space-around"
        align="middle"
        class=""
        style="min-height: 60vh"
      >
        <a-col
          :span="24"
          :md="12"
          :lg="12"
          class="text-center justify-items-center"
        >
          <label>{{ message }}</label>
        </a-col>
      </a-row>
    </div>

    <div v-if="loadingPath">
      <a-row
        type="flex"
        :gutter="24"
        justify="space-around"
        align="middle"
        class="text-center"
        style="min-height: 80vh"
      >
        <a-col
          :span="24"
          :md="12"
          :lg="12"
          class="text-center justify-items-center"
        >
          <a-spin class="text-primary" size="large" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

    
<script>
import { DoubleBounce } from "vue-loading-spinner";
import pdf from "vue-pdf";
export default {
  components: {
    DoubleBounce,
    pdf,
  },
  data() {
    return {
      message: "",
      pdfFilePath: null,
      loadingPath: false,
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
      zoom: 8,
    };
  },
  created() {
    this.handleReadFile();
    this.loadingPath = true;
    this.getFilePath();
  },
  computed: {
    proxyUrl() {
      return ""; //"https://corsproxy.io/?";
    },
  },
  methods: {
    keydown: function (e) {
      console.log(e);
    },
    zoomInPdf() {
      if (this.zoom < 24) {
        this.zoom = this.zoom + 2;
      }
    },
    zoomOutPdf() {
      if (this.zoom > 8) {
        this.zoom = this.zoom - 2;
      }
    },
    previousPage() {
      if (this.page > 1) {
        this.page = this.page - 1;
      }
    },
    nextPage() {
      let totalPages = this.numPages;

      if (this.page < totalPages) {
        this.page = this.page + 1;
      }
    },
    async getFilePath() {
      const pdfFileUrl = await localStorage.getItem("pdfFilePath");
      const fileName = await localStorage.getItem("pdfFileName");

      this.pdfFilePath = pdfFileUrl;

      console.log(pdfFileUrl);
      console.log(fileName);

      this.loadingPath = false;

      // this.message = '';

      // let token = await localStorage.getItem("user_token")

      // let timestamp = new Date();

      // const newFileName = timestamp.getTime() + "_" + fileName;

      // let payload = {
      //     fileName: newFileName,
      //     url: pdfFileUrl
      // }

      // let url = `${this.$BACKEND_URL}/download-file`;

      // const config = {
      // 	headers: {
      // 		'Content-Type': 'application/json',
      // 		'Authorization': `Bearer ${token}`,
      // 	},
      // };

      // this.$AXIOS.post(url, payload, config).then((response) => {
      //     if (response.status >= 200 || response.status <= 210) {

      // 		this.pdfFilePath = `${this.$BACKEND_URL}/downloads/${response.data.file}`;

      //     }

      // 	this.loadingPath = false;
      // }).catch(error => {

      //     this.loadingPath = false;
      //     this.message = "Something went wrong, try again!"

      // });
    },

    async handleReadFile() {
      let notesUuid = await localStorage.getItem("notesUuid");

      console.log(notesUuid);

      if (notesUuid != null && notesUuid.length > 20) {
        const userDetails = JSON.parse(
          await localStorage.getItem("user_details")
        );

        let url = `${this.$BACKEND_URL}/topics?uuid=${notesUuid}`;

        this.$AXIOS
          .get(url)
          .then(async (response) => {
            if (response.data.data != null) {
              let readers = response.data.data.completed;

              if (!readers.includes(userDetails.uuid)) {
                this.recordCompleted(notesUuid);
              } else {
                console.log("You read");
              }
            }
          })
          .catch((err) => {});
      }

      // alert("Noooo2")
    },

    recordCompleted(notesUuid) {
      let url = `${this.$BACKEND_URL}/topics/completed/${notesUuid}`;

      this.$AXIOS
        .post(url, { url: "dummy" })
        .then(async (response) => {
          console.log("now you read");

          await localStorage.setItem("notesUuid", null);
        })
        .catch((err) => {});
    },
  },
};
</script>
    <style scoped>
#inputPage {
  width: 40px;
  height: 32px;
  text-align: center !important;
  padding-left: 4px;
  border-radius: 2px;
  padding-right: 4px;
  font-size: 12px;
  line-height: 16px;
  background: #f7f7f7;
  border: 1px solid #b6b6b6;
  /* text-align: right;  */
  margin: 0;
  border-radius: 2px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>