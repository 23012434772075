import Vue from "vue";
import VueRouter from "vue-router";
import routeManager from "./routeManager";

Vue.use(VueRouter);

const checkAuth = async function (to, from, next) {
  // console.log(!window.localStorage.getItem("user_token"));
  let token = window.localStorage.getItem("user_token");

  await window.localStorage.setItem("previousUrl", to.fullPath);
  routeManager.setPreviousRoute(from);

  if (!token || token == "null") {
    next("/sign-in");
  } else {
    next();
  }
};

let routes = [
  {
    // will match everything
    path: "*",
    component: () => import("../views/404.vue"),
    beforeEnter: checkAuth,
  },
  // {
  // 	path: '/',
  // 	name: 'HomePage',
  // 	component: () => import('../views/Home/Index.vue'),
  // },
  {
    path: "/home",
    name: "Home",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Home/Index.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/layout",
    name: "Layout",
    layout: "dashboard",
    component: () => import("../views/Layout.vue"),
    beforeEnter: checkAuth,
  },

  // INSTITUTION LISTS
  {
    path: "/institutions-list",
    name: "List of Institutions",
    layout: "dashboard",
    component: () => import("../views/InstitutionList/Index.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions-list/view/:uuid",
    name: "Institution",
    layout: "dashboard",
    component: () => import("../views/InstitutionList/View.vue"),
    beforeEnter: checkAuth,
  },

  {
    path: "/institutions-list/view/:uuid/join",
    name: "Join Institution",
    layout: "dashboard",
    component: () => import("../views/InstitutionList/Join.vue"),
    beforeEnter: checkAuth,
  },

  // PROFILE
  {
    path: "/profile",
    name: "Profile",
    layout: "dashboard",
    component: () => import("../views/Profile/Index.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/profile/edit",
    name: "Edit Profile",
    layout: "dashboard",
    component: () => import("../views/Profile/EditProfile.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/profile/change-password",
    name: "Change Profile",
    layout: "dashboard",
    component: () => import("../views/Profile/ChangePassword.vue"),
    beforeEnter: checkAuth,
  },

  //CLASSES
  {
    path: "/classes",
    name: "Classes",
    layout: "dashboard",
    component: () => import("../views/Classes/Index.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/classes/view/:uuid",
    name: "Class Details",
    layout: "dashboard",
    component: () => import("../views/Classes/View.vue"),
    beforeEnter: checkAuth,
  },

  // NOTES
  {
    path: "/notes",
    name: "Notes",
    layout: "dashboard",
    component: () => import("../views/Notes/Index.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/notes/view/:uuid",
    name: "Notes Details",
    layout: "dashboard",
    component: () => import("../views/Notes/View.vue"),
    beforeEnter: checkAuth,
  },

  // TUTOR
  {
    path: "/tutor-classes",
    name: "Group Classes",
    layout: "dashboard",
    component: () => import("../views/Tutor/TutorClasses.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/tutor-quizzes",
    name: "Quizzes",
    layout: "dashboard",
    component: () => import("../views/Tutor/TutorQuiz.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/tutor-quizzes/view/:uuid",
    name: "View Quiz",
    layout: "dashboard",
    component: () => import("../views/Tutor/TutorQuizView.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/tutor-quizzes/view/:uuid/edit",
    name: "Edit Quiz",
    layout: "dashboard",
    component: () => import("../views/Tutor/EditQuiz.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/tutor-quizzes/view/:uuid/add-questions",
    name: "Add Question",
    layout: "dashboard",
    component: () => import("../views/Tutor/TutorQuizAddQuestion.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/tutor-quizzes/view/:uuid/edit-question/:questionUuid",
    name: "Edit Question",
    layout: "dashboard",
    component: () => import("../views/Tutor/TutorQuizEditQuestion.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/tutor-quizzes/view/:uuid/start",
    name: "Quiz Room",
    layout: "dashboard",
    component: () => import("../views/Tutor/TutorQuizRoom.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/tutor-quizzes/create",
    name: "Create Quiz",
    layout: "dashboard",
    component: () => import("../views/Tutor/TutorQuizCreate.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/tutor-notes",
    name: "Notes",
    layout: "dashboard",
    component: () => import("../views/Tutor/TutorNotes.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/tutor-categories",
    name: "Categories",
    layout: "dashboard",
    component: () => import("../views/Tutor/Categories.vue"),
    beforeEnter: checkAuth,
  },

  // INSTITUTIONS
  {
    path: "/institutions/classes",
    name: "Institution Classes",
    layout: "dashboard",
    component: () => import("../views/Institutions/Classes.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/notes",
    name: "Institution Notes",
    layout: "dashboard",
    component: () => import("../views/Institutions/Notes.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/quizzes",
    name: "Institution Quizzes",
    layout: "dashboard",
    component: () => import("../views/Institutions/Quizzes.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/categories",
    name: "Institution Quizzes",
    layout: "dashboard",
    component: () => import("../views/Institutions/Categories.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/students",
    name: "Institution Students",
    layout: "dashboard",
    component: () => import("../views/Institutions/Students.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/teachers",
    name: "Institution Teachers",
    layout: "dashboard",
    component: () => import("../views/Institutions/Teachers.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/admins",
    name: "Institution Admins",
    layout: "dashboard",
    component: () => import("../views/Institutions/Admins.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/:uuid/tutor-invitation",
    name: "Invitation Request",
    layout: "dashboard",
    component: () => import("../views/Institutions/TutorInvitation.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/:uuid/student-invitation",
    name: "Invitation Request",
    layout: "dashboard",
    component: () => import("../views/Institutions/StudentInvitation.vue"),
    beforeEnter: checkAuth,
  },

  //INSTITUTION  MY CLASSES
  {
    path: "/insitutions/my-classes",
    name: "My Classes",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionClasses/Index.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-classes",
    name: "My Classes",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionClasses/MyClasses.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-notes",
    name: "My Notes",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionClasses/MyNotes.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-quizzes",
    name: "My Quizzes",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionClasses/MyQuizzes.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-classes/view/:uuid",
    name: "View Class",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionClasses/View.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-classes/view/:uuid/live-class",
    name: "Live Class",
    layout: "",
    component: () =>
      import("../views/MyInstitutionClasses/Services/ClassroomConference.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-classes/view/:uuid/edit",
    name: "Edit Class Details",
    layout: "dashboard",
    component: () =>
      import("../views/MyInstitutionClasses/Setup/EditClass.vue"),
    beforeEnter: checkAuth,
  },
  // INSTITUTION  MY CLASSES TOPIC
  {
    path: "/institutions/my-classes/view/:uuid/topic/:topicUuid",
    name: "View Topic",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionClasses/Topic/View.vue"),
    beforeEnter: checkAuth,
  },

  // INSTITUTION  MY CLASSES QUIZ
  {
    path: "/institutions/my-classes/view/:uuid/topic/:topicUuid/quiz/:quizUuid",
    name: "View Quiz",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionClasses/Quiz/View.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-classes/view/:uuid/topic/:topicUuid/quiz/:quizUuid/add-question",
    name: "Add Question",
    layout: "dashboard",
    component: () =>
      import("../views/MyInstitutionClasses/Quiz/AddQuestion.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-classes/view/:uuid/topic/:topicUuid/quiz/:quizUuid/edit-question/:questionUuid",
    name: "Edit Question",
    layout: "dashboard",
    component: () =>
      import("../views/MyInstitutionClasses/Quiz/EditQuestion.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-classes/view/:uuid/topic/:topicUuid/quiz/:quizUuid/start",
    name: "Quiz Room",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionClasses/Quiz/QuizRoom.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-classes/view/:uuid/topic/:topicUuid/quiz/:quizUuid/results",
    name: "Quiz Result",
    layout: "dashboard",
    component: () =>
      import("../views/MyInstitutionClasses/Quiz/QuizResult.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-classes/create-new-class",
    name: "Create New Class",
    layout: "dashboard",
    component: () =>
      import("../views/MyInstitutionClasses/Setup/CreateClass.vue"),
    beforeEnter: checkAuth,
  },
  // {
  // 	path: '/institutions/my-classes/create-new-quiz',
  // 	name: 'Create New Quiz',
  // 	layout: "dashboard",
  // 	component: () => import('../views/MyInstitutionClasses/Setup/CreateQuiz.vue'),
  // 	beforeEnter: checkAuth,
  // },

  // QUIZZES
  {
    path: "/quizzes",
    name: "Quizzes",
    layout: "dashboard",
    component: () => import("../views/Quizzes/Index.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/quizzes/view/:uuid",
    name: "Quiz Details",
    layout: "dashboard",
    component: () => import("../views/Quizzes/View.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/quizzes/view/:uuid/start",
    name: "Quiz Room",
    layout: "dashboard",
    component: () => import("../views/Quizzes/QuizRoom.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/quizzes/view/:uuid/results",
    name: "Quiz Results",
    layout: "dashboard",
    component: () => import("../views/Quizzes/QuizResult.vue"),
    beforeEnter: checkAuth,
  },

  /// INSTITUTION MY QUIZZES
  {
    path: "/institutions/my-quizzes/view/:uuid",
    name: "Questions",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionQuiz/Index.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-quizzes/create-new-quiz",
    name: "Questions",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionQuiz/CreateQuiz.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-quizzes/view/:uuid/edit",
    name: "Edit Quiz",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionQuiz/EditQuiz.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-quizzes/view/:uuid/add-questions",
    name: "Add Question",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionQuiz/AddQuestion.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-quizzes/view/:uuid/edit-question/:questionUuid",
    name: "Edit Question",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionQuiz/EditQuestion.vue"),
    beforeEnter: checkAuth,
  },

  /// INSTITUTION MY NOTES
  {
    path: "/institutions/my-notes/publish-new-notes",
    name: "Publish New Notes",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionNotes/CreateNote.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/institutions/my-notes/:uuid/edit",
    name: "Edit Notes",
    layout: "dashboard",
    component: () => import("../views/MyInstitutionNotes/EditNote.vue"),
    beforeEnter: checkAuth,
  },

  // CALENDAR
  {
    path: "/calendar",
    name: "Calendar",
    layout: "dashboard",
    component: () => import("../views/Calendar/Index.vue"),
    beforeEnter: checkAuth,
  },
  // NOTIFICATIONS
  {
    path: "/notifications",
    name: "Notification",
    layout: "dashboard",
    component: () => import("../views/Notification/Index.vue"),
    beforeEnter: checkAuth,
  },

  // PAYMENT
  {
    path: "/payments/:uuid",
    name: "Payment",
    layout: "dashboard",
    component: () => import("../views/Payment/Index.vue"),
    beforeEnter: checkAuth,
  },
  // {
  // 	path: '/class-payments',
  // 	name: 'Class Payment',
  // 	layout: "dashboard",
  // 	component: () => import('../views/Payment/ClassPayment.vue'),
  // 	beforeEnter: checkAuth,
  // },

  // TUTORING JOBS
  {
    path: "/tutoring-jobs",
    name: "Tutoring Jobs",
    layout: "dashboard",
    component: () => import("../views/TutoringJobs/Index.vue"),
    beforeEnter: checkAuth,
  },

  // MY CLASSES
  {
    path: "/my-classes/view/:uuid/live-class",
    name: "Live Class",
    layout: "",
    component: () =>
      import("../views/MyClasses/Services/ClassroomConference.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-classes",
    name: "My Classes",
    layout: "dashboard",
    component: () => import("../views/MyClasses/Index.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-classes",
    name: "My Classes",
    layout: "dashboard",
    component: () => import("../views/MyClasses/MyClasses.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-notes",
    name: "My Notes",
    layout: "dashboard",
    component: () => import("../views/MyClasses/MyNotes.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-quizzes",
    name: "My Quizzes",
    layout: "dashboard",
    component: () => import("../views/MyClasses/MyQuizzes.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-classes/view/:uuid",
    name: "View Class",
    layout: "dashboard",
    component: () => import("../views/MyClasses/View.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-classes/view/:uuid/edit",
    name: "Edit Class Details",
    layout: "dashboard",
    component: () => import("../views/MyClasses/Setup/EditClass.vue"),
    beforeEnter: checkAuth,
  },

  // SETUP/NEW
  {
    path: "/my-classes/create-new-class",
    name: "Create New Class",
    layout: "dashboard",
    component: () => import("../views/MyClasses/Setup/CreateClass.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-classes/create-new-quiz",
    name: "Create New Quiz",
    layout: "dashboard",
    component: () => import("../views/MyClasses/Setup/CreateQuiz.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-classes/publish-new-notes",
    name: "Publish New Notes",
    layout: "dashboard",
    component: () => import("../views/MyClasses/Setup/CreateNote.vue"),
    beforeEnter: checkAuth,
  },

  // TOPIC
  {
    path: "/my-classes/view/:uuid/topic/:topicUuid",
    name: "View Topic",
    layout: "dashboard",
    component: () => import("../views/MyClasses/Topic/View.vue"),
    beforeEnter: checkAuth,
  },

  // QUIZ
  {
    path: "/my-classes/view/:uuid/topic/:topicUuid/quiz/:quizUuid",
    name: "View Quiz",
    layout: "dashboard",
    component: () => import("../views/MyClasses/Quiz/View.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-classes/view/:uuid/topic/:topicUuid/quiz/:quizUuid/add-question",
    name: "Add Question",
    layout: "dashboard",
    component: () => import("../views/MyClasses/Quiz/AddQuestion.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-classes/view/:uuid/topic/:topicUuid/quiz/:quizUuid/edit-question/:questionUuid",
    name: "Edit Question",
    layout: "dashboard",
    component: () => import("../views/MyClasses/Quiz/EditQuestion.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-classes/view/:uuid/topic/:topicUuid/quiz/:quizUuid/start",
    name: "Quiz Room",
    layout: "dashboard",
    component: () => import("../views/MyClasses/Quiz/QuizRoom.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/my-classes/view/:uuid/topic/:topicUuid/quiz/:quizUuid/results",
    name: "Quiz Result",
    layout: "dashboard",
    component: () => import("../views/MyClasses/Quiz/QuizResult.vue"),
    beforeEnter: checkAuth,
  },

  // // CLASSMATES
  // {
  // 	path: '/tutors',
  // 	name: 'Tutors',
  // 	layout: "dashboard",
  // 	component: () => import('../views/Tutors/Index.vue'),
  // 	beforeEnter: checkAuth,
  // },

  // TUTORS
  {
    path: "/tutors",
    name: "Tutors",
    layout: "dashboard",
    component: () => import("../views/Tutors/Index.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/tutors/view/:uuid",
    name: "Tutor Profile",
    layout: "dashboard",
    component: () => import("../views/Tutors/View.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/tutors/view/:uuid/booking",
    name: "Book Tutor",
    layout: "dashboard",
    component: () => import("../views/Tutors/Booking.vue"),
    beforeEnter: checkAuth,
  },

  // {
  // 	path: '/my-classes/view/:uuid/live-class',
  // 	name: 'Live Class',
  // 	layout: "",
  // 	component: LiveClass,
  // 	beforeEnter: checkAuth,
  // },
  // {
  // 	path: '/my-classes/view/:uuid/quiz/:quizUuid',
  // 	name: 'View Class Quiz',
  // 	layout: "dashboard",
  // 	component: ViewMyClassQuiz,
  // 	beforeEnter: checkAuth,
  // },
  // {
  // 	path: '/my-classes/view/:uuid/quiz/:quizUuid/add-question',
  // 	name: 'Add Question',
  // 	layout: "dashboard",
  // 	component: AddMyClassQuizQuestion,
  // 	beforeEnter: checkAuth,
  // },
  // {
  // 	path: '/my-classes/view/:uuid/quiz/:quizUuid/edit-question/:questionUuid',
  // 	name: 'Edit Question',
  // 	layout: "dashboard",
  // 	component: EditMyClassQuizQuestion,
  // 	beforeEnter: checkAuth,
  // },
  // {
  // 	path: '/my-classes/view/:uuid/notes-directory/:directoryUuid',
  // 	name: 'Notes',
  // 	layout: "dashboard",
  // 	component: Notes,
  // 	beforeEnter: checkAuth,
  // },
  // {
  // 	path: '/my-classes/view/:uuid/past-papers-directory/:directoryUuid',
  // 	name: 'Past Papers',
  // 	layout: "dashboard",
  // 	component: PastPapers,
  // 	beforeEnter: checkAuth,
  // },

  /// LEADS TUTOR REQUESTS
  {
    path: "/apply-tutoring-jobs/:tutorUuid",
    name: "Tutor Request",
    layout: "",
    component: () => import("../views/Leads/ApplyTutorJobs.vue"),
    // beforeEnter: checkAuth,
  },
  {
    path: "/class-request/:tutorUuid",
    name: "Tutor Request",
    layout: "",
    component: () => import("../views/Leads/ApplyTutorJobs.vue"),
    // beforeEnter: checkAuth,
  },

  // SERVICES
  {
    path: "/pdf-viewer",
    name: "Pdf Viewer",
    layout: "",
    component: () => import("../views/Services/PdfViewer.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/doc-viewer",
    name: "Doc Viewer",
    layout: "",
    component: () => import("../views/Services/DocViewer.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/excel-viewer",
    name: "Excel Viewer",
    layout: "",
    component: () => import("../views/Services/ExcelViewer.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/video-player",
    name: "Video Player",
    layout: "",
    component: () => import("../views/Services/VideoPlayer.vue"),
    beforeEnter: checkAuth,
  },
  {
    path: "/image-viewer",
    name: "Image Viewer",
    layout: "",
    component: () => import("../views/Services/ImageViewer.vue"),
    beforeEnter: checkAuth,
  },

  // {
  // 	path: '/Profile',
  // 	name: 'Profile',
  // 	layout: "dashboard",
  // 	meta: {
  // 		layoutClass: 'layout-profile',
  // 	},
  // 	component: () => import('../views/Profile.vue'),
  // 	beforeEnter: checkAuth,
  // },

  // AUTHENTICATION
  {
    path: "/",
    name: "Sign-In",
    component: () => import("../views/Auth/Sign-In.vue"),
  },
  {
    path: "/privacy-policy",
    name: "Sign-In",
    component: () => import("../views/Auth/PrivacyPolicy.vue"),
  },
  {
    path: "/sign-in",
    name: "Sign-In",
    component: () => import("../views/Auth/Sign-In.vue"),
  },
  {
    path: "/verify-account/:username",
    name: "Verify Account",
    component: () => import("../views/Auth/VerifyAccount.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () => import("../views/Auth/ForgotPassword.vue"),
  },
  {
    path: "/sign-up-student",
    name: "Sign-Up-Student",
    meta: {
      layoutClass: "layout-sign-up",
    },
    component: () => import("../views/Auth/Sign-Up-Student.vue"),
  },
  {
    path: "/sign-up-tutor",
    name: "Sign-Up-Tutor",
    meta: {
      layoutClass: "layout-sign-up",
    },
    component: () => import("../views/Auth/Sign-Up-Tutor.vue"),
  },
  {
    path: "/sign-up-parent",
    name: "Sign-Up-Parent",
    meta: {
      layoutClass: "layout-sign-up",
    },
    component: () => import("../views/Auth/Sign-Up-Parent.vue"),
  },
  {
    path: "/sign-up-institute",
    name: "Sign-Up-Institute",
    meta: {
      layoutClass: "layout-sign-up",
    },
    component: () => import("../views/Auth/Sign-Up-Institute.vue"),
  },
  {
    path: "/sign-up",
    name: "Sign-Up",
    meta: {
      layoutClass: "layout-sign-up",
    },
    component: () => import("../views/Auth/Sign-Up.vue"),
  },

  // ON-BOARDING
  {
    path: "/onboarding-student",
    name: "On-boarding Student",
    component: () => import("../views/OnBoarding/Onboarding-Student.vue"),
  },
  {
    path: "/onboarding-parent",
    name: "On-boarding Parent",
    component: () => import("../views/OnBoarding/Onboarding-Parent.vue"),
  },
  {
    path: "/onboarding-institute",
    name: "On-boarding Institute",
    component: () => import("../views/OnBoarding/Onboarding-Institute.vue"),
  },
  {
    path: "/onboarding-tutor",
    name: "On-boarding Tutor",
    component: () => import("../views/OnBoarding/Onboarding-Tutor.vue"),
  },
];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = route.layout || parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

export default router;
